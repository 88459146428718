.detail-section {
  background: $c5;
  padding-bottom: 30px;
  .inner {
    text-align: center;
    min-height: 400px;
    background: $c1;
    padding: 70px;
    position: relative;
    top: -70px;
    @media screen and (max-width: 800px){
      padding: 50px;
      text-align: center;
    }
    @media screen and (max-width: 600px){
      padding: 30px;
    }
    h1, h2, h3, h4, h5, h6 {
      text-align: left;
    }
    p {
      text-align: left;
    }
    p + p {
      margin-top: 20px;
    }
    hr {
      margin: 10px auto 30px 0;
    }
  }
}