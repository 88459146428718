.navigation {
  overflow: hidden;
  background: rgba(255, 255, 255, .5);
  position: fixed;
  top: 0;
  z-index: 3;
  width: 100%;
  transition: $trans;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 30px;
    background: $c5;
    transition: $trans;
  }
  .scrolltop{
    position: fixed;
    bottom: 30px;
    right: -3000px;
    margin: 0;
    padding: 0;
    height: 50px;
    width: 50px;
    background: $c1;
    border : 2px solid $c3;
    opacity: 0;
    outline : none;
    transition: opacity .5s ease, right .5s ease .5s;
    @media screen and (max-width: 800px){
      display: none;
    }
      &:before{
      content: '';
      display: block;
      margin: 7px auto 0;
      transform: rotate(-45deg);
      width: 15px;
      height: 15px;
      transition: $trans;
      border-top : 2px solid $c3;
      border-right : 2px solid $c3;
    }
    &.shown{
      right: 30px;
      opacity: 1;
      transition: opacity .5s ease;
      &:hover{

      }
    }
  }
  .login-modal, .language-select, .search-modal{
    .err{
      display : none;
      margin-bottom : 15px;
      &.shown{
        display : block;
      }
    }
  }
  .language-select{
    @media screen and (max-width: 800px){
      .cta{
        display: block;
        width: 100%;
        &:not(:last-child){
          margin-bottom: 10px;
        }
      }
    }
  }
  .website-options {
    transition: $trans;
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    overflow: hidden;
    button {
      font-weight: 600;
      color: $c3;
      position: relative;
      font-family: $f3;
      font-size: 13px;
      border: none;
      background: none;
      outline: none;
      padding: 0 15px;
      transition: $trans;
      border-radius :0;
      &:not(:last-child){
        border-right : 1px solid $c3;
      }
      &:hover {
        color: $c2;
      }
    }
  }
  .website-pages {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    height: 90px;
    @media screen and (max-width: 1000px){
      height: 75px;
    }
      .burger-button {
      display: none;
      @media screen and (max-width: 1000px){
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        background: transparent;
        border: 2px solid $c3;
        padding: 0;
        outline:none;
        position: relative;
        span{
          display: block;
          width: 30px;
          height: 2px;
          background: $c3;
          position: relative;
          &:before, &:after{
            content: '';
            position: absolute;
            display: block;
            width: 30px;
            height: 2px;
            background: $c3;
            transition: $trans;
          }
          &:before{
            transform: translate(0,-6px) rotate(0);
          }
          &:after{
            transform: translate(0,6px) rotate(0);
          }
        }
      }
    }
    a {
      font-family: $f2;
      position: relative;
      font-size: 15px;
      font-weight: 600;
      letter-spacing:3px;
      color: $c4;
      padding: 7px 10px;
      text-transform: uppercase;
      &:not(:nth-child(1)) {
        margin-left: 50px;
        transition: $trans;
        @media screen and (max-width: 1000px){
          display: none;
        }
          &:not(:nth-child(2)) {
          &:before,
          &:hover:before {
            content: "/";
            display: inline-block;
            position: absolute;
            left: -30px;
            color:$c4;
          }
        }
        &:hover {
          background: $c4;
          color: $c1;
        }
        &.active, &.active:hover {
          background: $c3;
          color: $c1;
        }
      }
      &:nth-child(1) {
        flex-grow: 1;
      }
      img {
        width : 160px;
      }
    }
  }
  .links-modal{
    a{
      box-sizing: border-box;
      display: block;
      width: 100%;
      background: $c3;
      color:$c1;
      padding: 20px 0;
      transition: $trans;
      text-transform: uppercase;
      font-family: $f2;
      letter-spacing: 3px;
      font-size: 20px;
      &:not(:first-child){
        margin-top: 10px;
      }
    }
  }
  &.fixed{
    background: rgba(255, 255, 255, 1);
    &:before, .website-options{
      height: 0;
    }
    .website-pages{
      height: 60px;
    }
  }
}

.navigation + * {
  margin-top: 120px;
}