.cookieBanner {
    position   : fixed;
    z-index : 5000;
    width      : 100%;
    bottom     : 0;
    display    : none;
    text-align : center;
    background : $c5;
    padding    : 20px 0;
    .inner {
        display        : flex;
        flex-direction : row;
        p {
            flex-grow : 1;
            color: $c2;
            a{
                text-decoration : underline;
                color:$c3;
            }
        }
        .close {
            background    : $c3;
            border        : none;
            width         : 24px;
            height        : 24px;
            border-radius : 50%;
            position      : relative;
            padding       : 0;
            margin        : 0;

            &:after,
            &:before {
                content    : '';
                display    : block;
                position   : absolute;
                top        : 50%;
                left       : 50%;
                width      : 18px;
                height     : 2px;
                background : $c1;
            }

            &:before {
                transform : translate(-50%, -50%) rotate(-45deg);
            }

            &:after {
                transform : translate(-50%, -50%) rotate(45deg);
            }

            &:hover {
                background : $c1;

                &:before,
                &:after {
                    background : $c3;
                }
            }
        }
    }
}