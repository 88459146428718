// -----------------------------------------------------------------------------
// This file contains basic typographic styles.
// -----------------------------------------------------------------------------
::selection{
    background: $c3;
    color:$c2;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin         : 0;
    padding-bottom : 15px;
}

h1 {
    color       : $c3;
    font-family : $f1;
    font-size   : $fz1;
}

h2 {
    color       : $c3;
    font-family : $f1;
    font-size   : $fz2;
}

h3 {
    text-transform: uppercase;
    font-size: 70px;
    font-weight: bold;
    text-align: center;
    margin: 50px auto;
    font-family: $f2;
    color: $c4;
    position: relative;
    z-index: 1;
    box-sizing: border-box;
    width     : 90%;
    max-width : $maxw;
    &:after{
        content: '';
        display: block;
        background: $c3;
        width: 100px;
        height: 1px;
        margin: 30px auto 0;
    }
    @media screen and (max-width: 800px){
        font-size: 50px;
        margin: 0 auto;
    }
    @media screen and (max-width: 600px){
        font-size: 35px;
    }
}

textarea{
    resize: none;
}

h4 {
    color       : $c3;
    font-family : $f1;
    font-size   : $fz4;
}

h5 {
    font-family :$f1;
    text-transform : uppercase;
    color: $c4;
    font-size : 30px;
    letter-spacing : 3px;
    @media screen and (max-width: 1000px){
        font-size: 25px;
    }
    @media screen and (max-width: 800px){
        font-size: 22px;
        letter-spacing : 2px;
    }
    @media screen and (max-width: 600px){
        font-size: 18px;
        letter-spacing : 1px;
    }
}

h6 {
    color       : $c3;
    font-family : $f1;
    font-size   : $fz6;
}
hr{
    border-top : 1px solid $c3;
    border-bottom : 0px solid transparent;
    background : $c3;
    width : 100px;
    margin-top : 10px;
    margin-bottom : 30px;
}
ul{
    margin-top: 20px;
}
p, li, a {
    color       : $c4;
    font-family : $f3;
    font-size   : $fz7;
    line-height : $lh7;
    @media screen and (max-width: 600px){
        font-size: 13px;
    }
}
a,
a:hover,
a:visited {
    color           : $c3;
    text-decoration : none;
}
a[href^="mailto"]{ text-decoration : underline;}

.big-font{
    h5{
        font-size :35px;
    }
    h6{
        font-size :30px;
    }
    p, li, a{
        font-size :25px;
    }
}