.contact-infos{
    overflow-x: hidden;
    background : $c1;
    padding: 70px 0;
    position: relative;
    @media screen and (max-width: 1200px){
        background: $c2;
    }
    .modal{
        input {
            width: calc(50% - 8px);
            &:nth-child(odd){
                margin-left: 12px;
            }
            @media screen and (max-width: 800px) {
                width: 100%;
                &:nth-child(odd){
                    margin-left: 0;
                }
            }
        }
        textarea{
            height: 170px;
        }
    }
    .inner{
        .txt{
            position: relative;
            z-index: 1;
            width: calc(60% - 70px);
            margin: 0 0 0 auto;
            background: $c1;
            padding: 70px;
            @media screen and (max-width: 1000px){
                width: 100%;
                box-sizing: border-box;
            }
            @media screen and (max-width: 800px){
                padding: 50px;
                text-align: center;
            }
            @media screen and (max-width: 600px){
                padding: 30px;
            }
            hr{
                margin: 10px auto 30px 0;
                @media screen and (max-width: 800px){
                    display: none;
                }
            }
            h5{
                @media screen and (max-width: 800px){
                    display: none;
                }
            }
        }
    }
}