.expertises-list {
    padding : 70px 0;
    overflow-x: hidden;
    .expertise-item {
        position: relative;
        .img {
            position : absolute;
            top      : 0;
            bottom   : 0;
            width    : 100%;
            img {
                height : 100%;
            }
            @media screen and (max-width: 800px){
                display: none;
            }
        }
        .txt {
            position      : relative;
            box-sizing    : border-box;
            width         : calc(60% + 70px);
            background    : $c1;
            display       : block;
            padding       : 50px 70px;
            @media screen and (max-width: 800px){
                padding: 50px;
                width: 100%;
                text-align: center;
            }
            @media screen and (max-width: 600px){
                padding: 30px;
            }
            &:before {
                content    : '';
                display    : block;
                height     : 1px;
                background : $c3;
                width      : 50px;
                position   : absolute;
                top        : 63px;
                @media screen and (max-width: 800px){
                    display: none;
                }
            }
        }
        &:nth-child(odd) {
            margin : 30px 0 30px auto;

            h5 {
                text-align : left;
                @media screen and (max-width: 800px){
                    text-align: center;
                }
            }
            .img {
                left : 0;
                img {
                    margin : 0 0 0 auto;
                }
            }
            .txt {
                margin : 0 0 0 auto;
                &:before {
                    right : calc(100% - 25px);
                }
            }
        }
        &:nth-child(even) {
            margin     : 30px auto 30px 0;
            text-align : right;
            h5 {
                text-align : right;
                @media screen and (max-width: 800px){
                    text-align: center;
                }
            }
            .img {
                right : 0;
            }
            .txt {
                margin : 0 auto 0 0;
                &:before {
                    left : calc(100% - 25px);
                }
            }
        }
    }
}