// -----------------------------------------------------------------------------
// This file contains styles that are specific to the styleguides page.
// -----------------------------------------------------------------------------

.styleguides {
    width     : 90%;
    max-width : 800px;
    margin    : 0 auto;
    font-size : 0;

    section {
        padding    : 50px;
        background : #f5f5f5;
    }

    & > h1 {
        box-sizing    : border-box;
        margin-top    : 50px;
        padding       : 30px;
        color         : $c3;
        background    : #f5f5f5;
        margin-bottom : 30px;
        text-align    : center;
        width         : 100%;
    }

    .colors, .texts, .typography {
        box-sizing    : border-box;
        margin-bottom : 30px;
    }

    .colors {
        text-align : center;
        .color-output-hex,
        .color-output-rgb {
            font-family : $f1;
            display     : none;
            background  : #f5f5f5;
            color       : $c3;
            width       : 100%;
            height      : 30px;
            font-size   : 18px;
            z-index     : 10;
        }
        .color-output-hex {
            margin-top     : 30px;
            text-transform : uppercase;
        }
        .colorblock {
            display       : inlineblock;
            border        : none;
            outline       : none;
            border-radius : 50%;
            position      : relative;
            margin        : 0 auto;
            transition    : .3s all ease;
            transform     : rotate(-45deg);
            width         : 150px;
            height        : 150px;
            &:hover {
                transform     : translate(0, -20px) rotate(-45deg);
                border-radius : 50% 50% 50% 0;
            }

            &:nth-of-type(1) {
                background : $c1;
                z-index    : 5;
            }

            &:nth-of-type(2) {
                background  : $c2;
                margin-left : -75px;
                z-index     : 4;
            }

            &:nth-of-type(3) {
                background  : $c3;
                margin-left : -75px;
                z-index     : 3;
            }

            &:nth-of-type(4) {
                background  : $c4;
                margin-left : -75px;
                z-index     : 2;
            }

            &:nth-of-type(5) {
                background  : $c5;
                margin-left : -75px;
                z-index     : 1;
            }

            span {
                position    : absolute;
                font-family : $f1;
                font-size   : 15px;

                &:nth-of-type(1) {
                    bottom : -30px;
                }

                &:nth-of-type(2) {
                    bottom         : -50px;
                    text-transform : uppercase;
                }
            }
        }
    }

    .texts {
        > * {
            display         : flex;
            flex-wrap       : wrap;
            justify-content : space-between;
        }
        span:first-child {
            width      : 50px;
            text-align : center;
        }
        span:last-child {
            width : calc(100% - 80px);
        }
    }

    .typography {
        div > div {
            position : relative;
            left     : 160px;
            height   : 120px;
        }

        div > div > p {
            margin-bottom : 10px;
            font-size     : 20px;
        }

        div > div:before {
            content   : 'Aa';
            position  : absolute;
            top       : 0;
            left      : -160px;
            font-size : 91px;
        }

        .font1 {
            margin-bottom : 50px;
            h3 {
                text-align    : center;
                margin-bottom : 30px;
                font-family   : $f1;
            }

            & > div:before {
                color       : $c3;
                font-family : $f1;
            }

            p {
                font-family : $f1;
            }
        }

        .font2 {
            h3 {
                text-align    : center;
                margin-bottom : 30px;
                font-family   : $f2;
            }

            & > div:before {
                color       : $c3;
                font-family : $f2;
            }

            p {
                font-family : $f2;
            }
        }
    }
}
