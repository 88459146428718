.googleMap {
    position : absolute;
    top      : 0px;
    right    : 0px;
    bottom   : 0px;
    left     : 0px;
    overflow   : hidden;
    background : $c3;
    @media screen and (max-width: 1200px){
        display: none;
    }

    .gmaps {
        position : absolute;
        top      : 0px;
        right    : 0px;
        bottom   : 0px;
        left     : 0px;
    }
}
