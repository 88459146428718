.career-intro {
    overflow-x: hidden;
    background : $c5;
    padding    : 70px 0;
    h3 {
        color : $c1;
    }
    .inner {
        margin-bottom : 30px;
        background    : $c1;
        padding       : 70px;
        @media screen and (max-width: 800px){
            padding: 50px;
            text-align: center;
        }
        @media screen and (max-width: 600px){
            padding: 30px;
        }
        hr {
            margin-left : 0;
            @media screen and (max-width: 800px){
                margin-left : auto;
            }
        }
        .cta {
            margin-right : 0;
        }
    }
}