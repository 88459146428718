.simple-header {
  box-sizing: border-box;
  background-color: $c2;
  background-repeat:no-repeat;
  background-position: 0 50%;
  background-size: 45%;
  padding-top: 100px;
  padding-bottom: 120px;
  display: flex;
  min-height:400px;
  flex-direction: column;
  justify-content: center;
  @media screen and (max-width: 1400px){
    background-size: 70%;
  }
  @media screen and (max-width: 1000px){
    background-size: cover;
  }
  h1 {
    margin: 0 0 0 auto;
    max-width: 70%;
    text-align: right;
    font-weight: 600;
    color: $c4;
    font-size: 80px;
    line-height: .9;
    @media screen and (max-width: 1000px){
      max-width: 100%;
      font-size: 70px;
      padding-right: 0;
    }
    @media screen and (max-width: 800px){
      font-size: 60px;
    }
    @media screen and (max-width: 600px){
      font-size: 40px;
      text-align: center;
    }
  }
}

.navigation + .simple-header {
  margin-top: 0;
  padding-top: 200px;
}