.footer {
  background: $c5;
  overflow-x: hidden;
  .inner{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    height: 80px;
    @media screen and (max-width: 1000px){
      flex-direction: column;
      height: auto;
      padding: 40px 0;
    }
  }
  a, button {
    background: none;
    border:none;
    position: relative;
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 3px;
    color: $c3;
    padding: 7px 10px;
    text-transform: uppercase;
    transition:$trans;
    &:hover{
      color:$c2;
    }
    &:nth-child(1){
      flex-grow: 1;
    }
    img {
      width : 160px;
      @media screen and (max-width: 1000px){
      margin-bottom: 20px;
    }
    }
  }
  .legal-mentions{
    border: 1px solid $c3;
    margin-left: 30px;
    @media screen and (max-width: 1000px){
      margin-left: 0;
      margin-top: 20px;
    }
    &:hover{
      color:$c3;
      background: $c2;
      border: 1px solid $c2;
    }
  }
}