// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------
// Colors
$c1 : #FFFFFF; // Color 1
$c2 : #ececec; // Color 2
$c3 : #747041; // Color 3
$c4 : #454545; // Color 4
$c5 : #292929; // Color 5
// Spacing
$bp1 : 600px; // Break-point 1
$bp2 : 800px; // Break-point 2
$maxw : 1000px; // Inner max-width
$iw : 90%; // Inner width
$mp : 50px 0 0 0; // Main padding for sectionsz
$m : 50px auto 0; // Main margin for sections
// Fonts
$f1 : 'Playfair', serif; // Main font
$f2 : 'Faustina', serif; // Secondary font
$f3 : 'Open Sans', sans-serif;

$fz1 : 40px; // Font-size for h1
$fz2 : 35px; // Font-size for h2
$fz3 : 30px; // Font-size for h3
$fz4 : 25px; // Font-size for h4
$fz5 : 20px; // Font-size for h5
$fz6 : 16px; // Font-size for h6
$fz7 : 16px; // Font-size for p, a
$lh7 : 1.4; // Line-height for p, a

// Effects
$trans : all .4s ease;