.main-header {
  box-sizing: border-box;
  background-color: $c2;
  background-image: url(/assets/images/bg_homepage.png);
  background-repeat:no-repeat;
  background-position: 0 50%;
  background-size: 50%;
  padding-top: 100px;
  padding-bottom: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-x: hidden;
  @media screen and (max-width: 1400px){
    background-size: 70%;
  }
  @media screen and (max-width: 1000px){
    background-size: cover;
  }
  .txt{
    width: calc(60% - 70px);
    margin: 0 0 0 auto;
    background: $c1;
    padding: 70px;
    @media screen and (max-width: 1000px){
      width: 100%;
      box-sizing: border-box;
    }
    @media screen and (max-width: 800px){
      padding: 50px;
      text-align: center;
    }
    @media screen and (max-width: 600px){
      padding: 30px;
    }
  }
  h1 {
    text-align: center;
    font-weight: 600;
    color: $c4;
    font-size: 160px;
    //@media screen and (min-width: 1000px){
    //  width : 548px;
    //  margin-right : 0;
    //  margin-left :auto;
    //}
    @media screen and (max-width: 1000px){
      font-size: 140px;
      padding-right: 0;
    }
    @media screen and (max-width: 800px){
      font-size: 120px;
    }
    @media screen and (max-width: 600px){
      font-size: 95px;
      text-align: center;
    }
    span {
      color: $c3;
    }
  }
  h2 {
    font-family: $f1;
    text-transform: uppercase;
    font-size: 30px;
    margin-top: -25px;
    margin-bottom: 50px;
    text-align: center;
    letter-spacing: 7px;
    color: $c4;
    //@media screen and (min-width: 1000px){
    //  width : 548px;
    //  margin-right : 0;
    //  margin-left :auto;
    //}
    @media screen and (max-width: 1000px){
      font-size: 25px;
    }
    @media screen and (max-width: 800px){
      letter-spacing: 6px;
      font-size: 22px;
    }
    @media screen and (max-width: 600px){
      font-size: 22px;
      letter-spacing : 1.6px;
      text-align: center;
    }
  }
  hr{
    margin: 10px auto 30px 0;
    @media screen and (max-width: 800px){
      margin: 10px auto 30px auto;
    }

  }
}

.navigation + .main-header {
  margin-top: 0;
  padding-top: 200px;
}