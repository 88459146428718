.modal {
    box-sizing: border-box;
    z-index    : 2000;
    overflow-x   : hidden;
    overflow-y: auto;
    height     : 0;
    position   : fixed;
    top        : 0;
    left       : 0;
    width      : 100%;
    background : rgba(7, 10, 0, .9);
    opacity    : 0;
    transition : opacity .5s ease, height 0s ease .5s;
    display : flex;
    flex-direction : column;
    justify-content: center;
    .inner{
        position : relative;
        background : $c1;
        text-align : center;
        padding : 70px;
        box-sizing : border-box;
        @media screen and (max-width: 800px){
            padding: 50px;
        }
        @media screen and (max-width: 600px){
            padding: 30px;
        }
    }

    &.shown {
        opacity    : 1;
        height     : 100%;
        transition : opacity .5s ease, height 0s ease 0s;
    }
    h5{
        font-family :$f1;
        text-transform : uppercase;
        color: $c4;
        letter-spacing : 3px;
    }
    input, textarea{
        width : 100%;
        font-family : $f2;
        outline:none;
        padding : 10px;
        margin-bottom : 15px;
        border: 1px solid transparent;
        background : $c2;
        box-sizing : border-box;
        &:focus, &:hover{
            border: 1px solid $c3;
        }
    }
    button{
        display : block;
        margin : 0 0 0 auto;
    }

    .modalClose {
        position   : absolute;
        top        : 0px;
        right      : 0px;
        width      : 50px;
        height     : 50px;
        border     : none;
        background : $c2;
        outline    : none;
        border-left: 2px solid $c2;
        border-bottom: 2px solid $c2;
        transition: $trans;

        &:after,
        &:before {
            content    : '';
            display    : block;
            position   : absolute;
            top        : 50%;
            left       : 50%;
            width      : 25px;
            height     : 2px;
            background : $c3;
        }

        &:before {
            transform : translate(-50%, -50%) rotate(-45deg);
        }

        &:after {
            transform : translate(-50%, -50%) rotate(45deg);
        }

        &:hover {
            background : $c1;

            &:before,
            &:after {
                background : $c3;
            }
        }
    }
}
