.language-selector {
    box-sizing: border-box;
    background-color: $c2;
    background-image: url(../images/bg_homepage.png);
    background-repeat:no-repeat;
    background-position: 0 50%;;
    background-size: 45%;
    height : 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media screen and (max-width: 1400px){
        background-size: 70%;
    }
    @media screen and (max-width: 1000px){
        background-size: cover;
    }

    h1 {
        text-align: right;
        padding-right: 30px;
        font-weight: 600;
        color: $c4;
        font-size: 160px;
        @media screen and (max-width: 1000px){
            font-size: 140px;
            padding-right: 0;
        }
        @media screen and (max-width: 800px){
            font-size: 120px;
        }
        @media screen and (max-width: 500px){
            font-size: 80px;
            text-align: center;
        }
        span {
            color: $c3;
        }
    }
    h2 {
        font-family: $f1;
        text-transform: uppercase;
        font-size: 30px;
        margin-top: -25px;
        text-align: right;
        letter-spacing: 3px;
        color: $c4;
        @media screen and (max-width: 1000px){
            font-size: 25px;
        }
        @media screen and (max-width: 800px){
            font-size: 22px;
        }
        @media screen and (max-width: 600px){
            font-size: 18px;
            letter-spacing : 1px;
            text-align: center;
        }
    }
    .languages{
        width : 100%;
        background : linear-gradient(to right, rgba(255,255,255,0) 15%,rgba(255,255,255,1) 30%,rgba(255,255,255,1) 100%);
        text-align : center;
        padding : 70px 0;
        margin-top : 50px;
        @media screen and (max-width: 600px){
            background: $c1;
        }
        a{
            color : $c4;
            display : inline-block;
            padding : 0 20px;
            &:nth-of-type(2){
                border-right : 2px solid $c3;
                border-left : 2px solid $c3;
            }
            &:hover{
                color:$c3;
                text-decoration : underline;
            }
        }
    }
}