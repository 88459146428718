// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------

a.cta,
button.cta {
    display         : inline-block;
    box-sizing      : border-box;
    margin          : 0 auto;
    padding         : 10px 25px 13px 30px;
    color           : $c2;
    border          : 2px solid $c3;
    background      : $c3;
    outline         : none;
    text-decoration : none;
    text-transform  : uppercase;
    font-size       : $fz6;
    font-family     : $f2;
    letter-spacing  : 3px;
    transition      : 0.3s all ease;
    font-weight: bold;
    line-height: 1.2;
    &:after{
        content : ' ▸';
    }

    &:hover {
        color      : $c3;
        border     : 2px solid $c3;
        background : $c2;
    }
}
p + a.cta,
p + button.cta{
    margin-top: 30px;
}

button{
    border-radius :0;
}

.inner {
    box-sizing: border-box;
    width     : 90%;
    max-width : $maxw;
    margin    : 0 auto;
}
